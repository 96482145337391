<template>
  <div class="section">
    <div class="container">
      <a-table
        :columns="columns"
        :data-source="itemList"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="false"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        row-key="id"
      >
        <template #itemName="{ record }">
          {{ record.item.title.en }}
        </template>
        <template #provider="{ record }">
          {{ getProviderName(record.providerId) }}
        </template>
        <template #status="{ record }">
          {{ $t(record?.status) }}
        </template>
        <template #deliveryDate="{ record }">
          {{ $filters.date(record.deliveryDate, { format: $variables.dateFormat }) }}
        </template>
        <template #deliveryTime="{ record }">
          {{ getTimeFromISOString(record?.deliveryDate) }}
        </template>
        <template #receiver="{ record }">
          {{ getReceiverName(houseKeeperList?.find((item) => item.uid === record.receiver) || {}) }}
        </template>
        <template #orderBefore="{ record }">
          {{ $filters.date(record.leadDate, { format: $variables.dateFormat }) }}
        </template>
        <template #unitPrice="{ record }">
          {{ `${$filters.number(record.unitPrice)} JPY` }}
        </template>
        <template #itemPrice="{ record }">
          <div class="item-price">
            {{ `${$filters.number(record.unitPrice * record.quantity)} JPY` }}
          </div>
        </template>
        <template #footer>
          <div class="is-flex is-align-items-center">
            <div class="has-text-primary has-text-weight-bold">
              {{ $t('Order Total amount') }}
            </div>
            <div style="margin: 0 2px 0 25px">
              {{ $filters.number(upsellItemOrder?.items?.total || 0) }} JPY
            </div>
          </div>
        </template>
      </a-table>
      <Modal ref="orderItem">
        <template #default>
          <EditOrderItemForm
            :value="selectedOrderItem"
            :providers="providerList"
            :receivers="houseKeeperList"
            @close="
              () => {
                resetField();
                fetchData();
              }
            "
            @cancel="resetField"
          />
        </template>
      </Modal>
    </div>
  </div>
</template>

<i18n src="@/locales/components/upsell-item-orders.json"></i18n>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/ModalWide';
import EditOrderItemForm from '@/views/upsell-item-orders/components/EditOrderItemForm';
import ReceiverName from '@/views/upsell-item-orders/mixins/ReceiverName';
import DateFormat from '@/views/upsell-item-orders/mixins/DateFormat';
import { RELATIONSHIPS } from '@/config/partners';

export default {
  name: 'ItemList',
  components: { Modal, EditOrderItemForm },
  mixins: [DateFormat, ReceiverName],
  data() {
    return {
      isFetching: false,
      selectedOrderItem: undefined,
      providerList: [],
      providerHash: {},
      houseKeeperList: [],
      columns: [
        {
          title: this.$t('Item'),
          dataIndex: 'itemName',
          key: 'itemName',
          slots: { customRender: 'itemName' },
        },
        {
          title: this.$t('Provider'),
          dataIndex: 'provider',
          key: 'provider',
          slots: { customRender: 'provider' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Delivery Date'),
          key: 'deliveryDate',
          dataIndex: 'deliveryDate',
          slots: { customRender: 'deliveryDate' },
        },
        {
          title: this.$t('Delivery time'),
          key: 'deliveryTime',
          dataIndex: 'deliveryTime',
          slots: { customRender: 'deliveryTime' },
        },
        {
          title: this.$t('Receiver'),
          key: 'receiver',
          dataIndex: 'receiver',
          slots: { customRender: 'receiver' },
        },
        {
          title: this.$t('Order before'),
          key: 'orderBefore',
          dataIndex: 'orderBefore',
          slots: { customRender: 'orderBefore' },
        },
        {
          title: this.$t('Provider reference'),
          key: 'providerReference',
          dataIndex: 'providerReference',
          slots: { customRender: 'providerReference' },
        },
        {
          title: this.$t('Unit Price'),
          key: 'unitPrice',
          dataIndex: 'unitPrice',
          slots: { customRender: 'unitPrice' },
          class: 'unit-price',
        },
        {
          title: this.$t('Quantity'),
          key: 'quantity',
          dataIndex: 'quantity',
          slots: { customRender: 'quantity' },
        },
        {
          title: this.$t('Item Price'),
          key: 'itemPrice',
          dataIndex: 'itemPrice',
          slots: { customRender: 'itemPrice' },
          width: '150px',
          class: 'item-price',
        },
      ],
    };
  },
  computed: {
    ...mapState('upsell-item-orders', ['upsellItemOrder']),
    itemList() {
      return this.upsellItemOrder?.items?.items || [];
    },
  },
  created() {
    this.getProviderList();
    this.getHouseKeeperList();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('upsell-item-orders/get', { id: this.$route.params.id });
    },
    getProviderList() {
      const params = { infinite: true, relationship: RELATIONSHIPS.UPSELL_ITEM_PROVIDER };
      this.$store.dispatch('partners/names', params).then((res) => {
        this.providerList = res.list;
        this.providerHash = Object.fromEntries(this.providerList.map(({ id, name }) => [id, name]));
      });
    },
    getHouseKeeperList() {
      this.$store
        .dispatch('user/users', {
          role: 'HKP',
          infinite: true,
        })
        .then((res) => {
          this.houseKeeperList = res.list;
        });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.selectedOrderItem = {
            id: record.id,
            providerId: record.providerId ? `${record.providerId}` : null,
            status: record.status,
            providerReference: record.providerReference,
            deliveryDate: record.deliveryDate ? dayjs(record?.deliveryDate).format('YYYY-MM-DD') : null,
            deliveryTime: this.getTimeFromISOString(record?.deliveryDate),
            receiver: record.receiver,
            changeableStatuses: record.changeableStatuses,
            availableProviders: record.item?.providers,
          };
          this.$refs.orderItem.show();
        },
      };
    },
    resetField() {
      this.$refs.orderItem.hide();
      this.selectedOrderItem = undefined;
    },
    getProviderName(providerId) {
      return this.providerHash[providerId];
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ant-table-body {
    min-width: 1350px;
  }

  .ant-table-tbody > tr > td {
    border-color: transparent;
  }

  .ant-table-title {
    padding: 0px;
  }

  .ant-table-footer {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    left: 0;
    position: sticky;
  }

  .item-price {
    text-align: right;
  }

  .ant-table-body tr td.unit-price {
    border-left: solid 1px #dcdcdc;
    border-left-color: #dcdcdc;
  }

  .unit-price {
    padding-left: 30px;
  }
}
</style>
