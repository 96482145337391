<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="is-flex is-flex-direction-column is-align-items-stretch"
    hide-required-mark
  >
    <h3 class="has-text-weight-bold is-size-4 is-8 popup-title">
      {{ $t('Edit Order Item') }}
    </h3>
    <div class="column is-12 p-0">
      <label class="label-color">
        {{ $t('Provider') }}:
      </label>
      <a-form-item
        style="margin-bottom: 20px"
        name="providerId"
      >
        <InputProvider
          v-model:value="form.providerId"
          :available-providers="availableProviders"
          :disabled="isHk || isCancelled"
          is-edit-item
        />
      </a-form-item>
    </div>
    <div class="column is-12 p-0">
      <label class="label-color">
        {{ $t('Status') }}:
      </label>
      <a-form-item style="margin-bottom: 20px">
        <a-select
          v-model:value="form.status"
          :size="size"
          :disabled="!changeableStatuses?.length"
          @change="handleChange"
        >
          <a-select-option
            v-for="item in statusList"
            :key="item.value"
            :value="item.value"
            :disabled="!changeableStatuses.includes(item.value)"
          >
            {{ $t(item.label) }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>
    <div class="column is-12 p-0">
      <label class="label-color">
        {{ $t('Provider Preference') }}:
      </label>
      <a-form-item style="margin-bottom: 15px">
        <a-input
          v-model:value="form.providerReference"
          :placeholder="$t('Provider reference')"
          :size="size"
          :disabled="isHk || isCancelled"
        />
      </a-form-item>
    </div>
    <div class="columns is-12 p-0">
      <div class="column is-6">
        <label class="label-color">
          {{ $t('Delivery Date') }}
        </label>
        <a-form-item
          class="mb-0"
          name="deliveryDate"
        >
          <a-date-picker
            v-model:value="form.deliveryDate"
            :size="size"
            style="width: 100%"
            :disabled="(isHk && !isReceived) || isCancelled"
          />
        </a-form-item>
      </div>
      <div class="column is-6">
        {{ $t('Delivery Time') }}
        <a-form-item
          class="mb-0"
          name="deliveryTime"
        >
          <a-time-picker
            v-model:value="form.deliveryTime"
            value-format="HH:mm"
            format="HH:mm"
            :size="size"
            :allow-clear="false"
            :disabled="(isHk && !isReceived) || isCancelled"
            style="width: 100%"
          />
        </a-form-item>
      </div>
    </div>
    <div class="column is-12 p-0">
      <label class="label-color">
        {{ $t('Receiver') }}:
      </label>
      <a-form-item name="receiver">
        <a-select
          v-model:value="form.receiver"
          :size="size"
          placeholder="Receiver"
          :disabled="isHk || isCancelled"
          @change="handleChange"
        >
          <a-select-option
            v-for="(r, index) in receivers"
            :key="index"
            :value="r?.uid"
          >
            {{ getReceiverName(r) }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>
    <div class="column has-text-right pr-0">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        :disabled="isCancelled && !changeableStatuses?.length"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/upsell-item-orders.json"></i18n>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import InputProvider from '@/components/InputProvider';
import { ORDER_STATUS, ORDER_STATUS_OPTION } from '@/config/upsell-item-orders';
import ReceiverName from '@/views/upsell-item-orders/mixins/ReceiverName';
import DateFormat from '@/views/upsell-item-orders/mixins/DateFormat';
import { ROLES } from '@/config/roles';
import checkRole from '@/utils/role';

export default {
  name: 'EditOrderItemForm',
  components: { InputProvider },
  mixins: [DateFormat, ReceiverName],
  props: {
    value: {
      type: Object,
      default() {
        return undefined;
      },
    },
    providers: {
      type: Array,
      default() {
        return [];
      },
    },
    receivers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['close', 'cancel', 'update:value'],
  data() {
    return {
      form: {
        id: undefined,
        status: undefined,
        providerId: undefined,
        providerReference: undefined,
        deliveryDate: undefined,
        deliveryTime: undefined,
        receiver: undefined,
      },
      loading: false,
      size: 'large',
      statusList: ORDER_STATUS_OPTION,
      changeableStatuses: [],
      isHk: checkRole([ROLES.HOUSEKEEPER]),
      availableProviders: [],
      rules: {
        receiver: [
          {
            trigger: 'submit',
            validator: (_, value) => {
              if (!value && (this.form.status === ORDER_STATUS.RECEIVED
                || this.form.status === ORDER_STATUS.ORDERED)
              ) {
                return Promise.reject(new Error(this.$t('Please input a receiver!')));
              }

              return Promise.resolve();
            },
          },
        ],
        providerId: [
          {
            trigger: 'submit',
            validator: (_, value) => {
              if (!value && (this.form.status === ORDER_STATUS.ORDERED
                || this.form.status === ORDER_STATUS.RECEIVED)
              ) {
                return Promise.reject(new Error(this.$t('Please input a provider!')));
              }
              if ((this.availableProviders.length && value && !this.availableProviders.map((provider) => `${provider.providerId}`).includes(value))
                || (!this.availableProviders.length && this.form.providerId)) {
                return Promise.reject(new Error(this.$t('This item is not linked to provider')));
              }

              return Promise.resolve();
            },
          },
        ],
        deliveryDate: [
          {
            trigger: 'submit',
            validator: (_, value) => {
              if (!value && this.form.status === ORDER_STATUS.RECEIVED) {
                return Promise.reject(new Error(this.$t('Please input delivery date!')));
              }

              return Promise.resolve();
            },
          },
        ],
        deliveryTime: [
          {
            trigger: 'submit',
            validator: (_, value) => {
              if (!value && this.form.status === ORDER_STATUS.RECEIVED) {
                return Promise.reject(new Error(this.$t('Please input delivery time!')));
              }

              return Promise.resolve();
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('upsell-item-orders', ['upsellItemOrder']),
    isCancelled() {
      return this.upsellItemOrder?.status === ORDER_STATUS.CANCELLED;
    },
    isReceived() {
      return this.form.status === ORDER_STATUS.RECEIVED;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
          this.changeableStatuses = nv.changeableStatuses.concat([nv.status]);
          this.availableProviders = nv.availableProviders;
        }
      },
    },
  },
  methods: {
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    async handleSubmit() {
      await this.$refs.form.validate();
      this.loading = true;
      try {
        if (this.form.id) {
          const newDateTime = new Date(`${dayjs(this.form.deliveryDate).format('YYYY-MM-DD')} ${this.form.deliveryTime}`);
          const data = {
            id: this.form.id,
            status: this.form.status,
            providerId: this.form.providerId ? this.form.providerId : undefined,
            providerReference: this.form.providerReference
              ? this.form.providerReference
              : undefined,
            deliveryDate: dayjs(newDateTime).isValid()
              ? dayjs(newDateTime).toISOString()
              : undefined,
            receiver: this.form.receiver ? this.form.receiver : undefined,
          };
          await this.$store.dispatch('upsell-item-orders/update', data);
          this.$message.success(this.$t('Successfully update order item'));
        }
        this.$refs.form.resetFields();
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popup-title {
  color: $rtx-green;
  margin-bottom: 27px;
}
</style>
