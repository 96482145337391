import { getFullname } from '@/utils/users';

export default {
  methods: {
    getReceiverName(receiver) {
      return getFullname({
        fname: receiver?.firstName,
        lname: receiver?.lastName,
      }) || '';
    },
  },
};
